<template>
  <v-container class="pa-0 ma-0">
    <v-row justify="center" align="center">
      <div :style="style" ref="lavContainer"></div>
    </v-row>
    <div style="width: 100%; display: flex">
      <v-text-field
        :label="$t('common.searchByPatient')"
        filled
        outlined
        color="grey lighten-1"
        required
        hide-details
        class="mb-2 search_patient"
        v-model="keySearch"
        clearable
        @click:clear="resetPatients()"
        @change="searchPatient"
      ></v-text-field>
      <v-btn
        @click="openAddPatient"
        class="text-capitalize btn_show_scanner"
        elevation="0"
      >
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </div>
    <div style="text-align: center" v-if="loading" class="mt-2">
      <v-progress-circular indeterminate color="#fefefe"></v-progress-circular>
    </div>
    <div v-else class="content_card">
      <h5 v-if="!patientOptions.length" class="no_data">
        {{ $t("common.nodata") }}
      </h5>
      <content-item
        v-for="(item, index) in patientOptions"
        :key="index"
        :item="item"
        @view="viewPatient(item)"
        v-show="index < $accountLimit"
        class="contact_item"
      >
        <template v-slot:controller>
          <v-list-item-icon class="pt-1 pb-1 pr-1">
            <v-btn
              x-small
              fab
              dark
              icon
              class="delete_btn"
              @click="
                $event.stopPropagation();
                deletePhoneContact(item.phone);
              "
              v-if="item.phone && item.phone != $phone"
            >
              <v-icon dark> mdi-trash-can-outline </v-icon>
            </v-btn>
            <lottie-ble
              :phone="item.phone"
              @doneWatching="doneWatching"
              @startWatching="startWatching"
            />
            <v-btn
              small
              color="#eeeeee"
              class="service_button mr-4"
              fab
              elevation="0"
              style="background-color: rgb(69, 78, 112)"
              :disabled="!item.phone || item.phone == $phone"
              @click="
                $event.stopPropagation();
                $store.commit('Mobile/SET_MESSAGE_PHONE_STRING', item.value);
              "
            >
              <v-icon>mdi-message-text </v-icon>
              <LottieNewMessage :phone="item.phone" />
            </v-btn>
            <v-btn
              small
              color="#eeeeee"
              class="service_button mr-2"
              fab
              elevation="0"
              style="background-color: rgb(69, 78, 112)"
              :disabled="!item.phone || item.phone == $phone"
              @click="
                $event.stopPropagation();
                callPhone(item.value);
              "
            >
              <v-icon>mdi-phone </v-icon>
            </v-btn>
          </v-list-item-icon>
        </template>
      </content-item>

      <p
        class="no_data"
        v-if="$accountLevel < 2 && patientOptions.length < $accountLimit"
      >
        {{ $t("common.youhave") }}
        {{ $accountLimit - patientOptions.length }}
        {{ $t("common.accountremain") }}
      </p>

      <p
        class="no_data orange--text"
        v-if="$accountLevel < 2 && patientOptions.length > $accountLimit"
      >
        {{ $t("common.youhavereachyouruserslimits") }}: {{ $accountLimit }}
      </p>
    </div>

    <div v-if="offlineEpisodes && offlineEpisodes.length" class="content_card">
      <content-item
        v-for="(item, index) in offlineEpisodes"
        :key="index"
        :item="item"
        @view="viewOfflineEpisode(item)"
      >
        <template v-slot:controller>
          <v-list-item-icon class="pt-1 pb-1 pr-1">
            <v-btn
              small
              color="#eeeeee"
              class="service_button"
              fab
              elevation="0"
              style="background-color: rgb(69, 78, 112)"
              :disabled="!item.patientId"
              @click="
                $event.stopPropagation();
                callPhone(item.patientId);
              "
            >
              <v-icon>mdi-phone </v-icon>
            </v-btn>
          </v-list-item-icon>
        </template>
      </content-item>
    </div>

    <popup-patient-detail
      ref="PopupPatientDetail"
      @selectedProfile="selectedProfile"
    />
  </v-container>
</template>

<script>
import lottie from "lottie-web";
import ContentItem from "@/components/cards/ContentItem";
// import moment from "moment";
import { standardizing } from "@/plugins/helpers";
import LottieBle from "@/components/LottieBle.vue";
import moment from "moment";
import PopupPatientDetail from "@/components/patient/PopupPatientDetail";
import axios from "axios";
import LottieNewMessage from "@/components/LottieNewMessage.vue";

export default {
  components: {
    ContentItem,
    LottieBle,
    PopupPatientDetail,
    LottieNewMessage,
  },
  computed: {
    offlineEpisodes() {
      var episodes = this.$store.getters["Offline/offlineEpisodes"] || [];
      return episodes.map((e) => ({
        ...e,
        title: e.name,
        content: `
            <span class="mt-2" style="font-size:0.85rem;">
              ${this.$t("common.date")} ${moment(e.date).format(
          "DD-MM-YYYY HH:mm"
        )}
            </span>
            `,
        message: `
            <i aria-hidden="true" class="v-icon notranslate mdi mdi-gauge theme--light" style="font-size: 0.9rem;"></i>
            <span style="font-size:0.8rem;">
              ${e.config && e.config.maxInflatePressure}
            </span>
            <span style="font-size:0.8rem;">mmHg</span>
            <i aria-hidden="true" class="ml-2 v-icon notranslate mdi mdi-repeat theme--light" style="font-size: 0.9rem;"></i>
            <span style="font-size:0.8rem;">
              ${e.config && e.config.calibsPerHour} ${this.$t("common.times")}
            </span>
            <span style="font-size:0.8rem;">/h</span>
            `,
        image: "/device.png",
        item: e,
      }));
    },
    style() {
      return {
        width: "220px",
        height: "220px",
        overflow: "hidden",
        margin: "0",
      };
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      keySearch: "",
      patientOptions: [],
      caches: [],
    };
  },
  async mounted() {
    this.getPatientOptions();
    this.renderBleAnimation();
    this.$store.commit("Scanner/SET_IS_MEASURING", false);
  },
  methods: {
    searchPatient() {
      if (!this.keySearch) return (this.patientOptions = this.caches);
      this.patientOptions = this.caches.filter(
        (i) =>
          i.value &&
          i.text &&
          (i.value.includes(this.keySearch) ||
            standardizing(i.text).includes(standardizing(this.keySearch)))
      );
    },
    resetPatients() {
      this.patientOptions = this.caches;
    },
    doneWatching(phone) {
      if (!phone || phone == "---") return;
      // var option = this.patientOptions.find((o) => o.phone == phone);
      // if (option) option.isMeasuring = false;
      // this.organizePatientOptions();
    },
    startWatching(phone) {
      if (!phone || phone == "---") return;
      // var option = this.patientOptions.find((o) => o.phone == phone);
      // if (option) option.isMeasuring = true;
      // this.organizePatientOptions();
    },
    openAddPatient() {
      this.$refs.PopupPatientDetail.open();
    },
    async deletePhoneContact(phone) {
      if (!phone || phone == "---") return;
      if (!confirm(`${this.$t("common.confirmDeleteContact")}`)) return;
      await this.$dbRemove(`users/${this.$uid}/patients/${phone}`);
      this.playNof();
      this.showSuccess(this.$t("common.successMessage"));
      this.getPatientOptions();
    },
    async selectedProfile({ id, phone }) {
      if (phone != this.$phone) {
        this.notifyInviteSubscribe(this.$uid, id);
      }
      this.getPatientOptions();
    },
    async notifyInviteSubscribe(inviterId, subscriberId) {
      if (!inviterId || !subscriberId) return;
      if (!this.networkStatus) return;
      var payload = { inviterId, subscriberId };
      var resp = await axios.post(
        "https://us-central1-sandrasoft-8fe2b.cloudfunctions.net/notifyInviteSubscribe",
        payload
      );
      console.log(resp.data);
    },
    async organizePatientOptions() {
      var options = JSON.parse(JSON.stringify(this.patientOptions));

      var isMeasuring = options
        .filter((o) => o.isMeasuring)
        .sort((a, b) => a.text.localeCompare(b.text));
      var notMeasuring = options
        .filter((o) => !o.isMeasuring)
        .sort((a, b) => a.text.localeCompare(b.text));

      console.log(isMeasuring);
      console.log(notMeasuring);

      this.patientOptions = [];
      this.$forceUpdate();
      await this.sleep(200);

      this.patientOptions = [...isMeasuring, ...notMeasuring];
    },
    async getPatientOptions() {
      var result = [];
      this.loading = true;
      var patients = await this.$dbGet(`users/${this.$uid}/patients`);
      this.loading = false;
      if (patients) {
        result = Object.keys(patients).map((key) => ({
          value: key,
          text: patients[key].fullName,
          phone: key,
        }));
        result = result.sort((a, b) => a.text.localeCompare(b.text));
      }
      result.push({
        value: "---",
        text: this.$t("common.undefined"),
      });
      var options = result.map((p) => ({
        ...p,
        // image: "/icon.png",
        image: false,
        title: p.text,
        content: "",
        message: `<span style="color:#e0e0e0;">${p.value || "NA"}</span>`,
        iconbel: true,
      }));
      var ind = options.findIndex((op) => op.phone == this.$phone);
      if (ind != -1) {
        var optionisMe = options[ind];
        options.splice(ind, 1);
        options.unshift(optionisMe);
      }
      this.patientOptions = options;
      this.caches = options;
    },
    viewPatient(item) {
      this.$emit("view", item);
      this.openInNewTabIfBrowser(`/profile/${item.value}`);
      // this.$router.push(`/profile/${item.value}`);
    },
    viewOfflineEpisode(item) {
      this.$emit("view", item);
      this.$router.push(`/history/${item.id}`);
    },
    renderBleAnimation() {
      lottie.loadAnimation({
        container: this.$refs.lavContainer,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/lottie/robot.json",
      });
    },
  },
};
</script>

<style lang="scss">
.search_patient {
  background-color: #313959;
  border-radius: 12px !important;
  label,
  input {
    margin-top: 0px !important;
    color: #e0e0e0 !important;
  }
}
.delete_btn {
  position: absolute;
  top: -1px;
  right: -1px;
}
// .contact_item {
//   .delete_btn {
//     display: none;
//   }
// }
// .contact_item:hover {
//   .delete_btn {
//     display: block;
//   }
// }
</style>
