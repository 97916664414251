<template>
  <div>
    <v-badge
      :color="badgeColorCode"
      class="mt-7"
      style="float: left; margin-right: 15px"
      v-show="showIcon"
      @click="
        $event.stopPropagation();
        openEpisode();
      "
    ></v-badge>
    <div
      ref="lav2Container"
      style="width: 65px; height: 65px; margin: -12px -6px; float: left"
      :style="{ opacity: showIcon ? 1 : 0 }"
      @click="
        $event.stopPropagation();
        openEpisode();
      "
    ></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import { BleSeverity } from "@/plugins/constants";
import { mapGetters } from "vuex";

export default {
  props: {
    phone: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters("Mobile", ["getSubscriptions"]),
  },
  watch: {
    getSubscriptions() {
      this.registerPhoneWatcher();
    },
  },
  data() {
    return {
      showIcon: false,
      badgeColorCode: "grey",
      episodeId: "",
    };
  },
  async created() {
    this.renderBleAnimation();
    this.registerPhoneWatcher();
  },
  methods: {
    openEpisode() {
      if (!this.episodeId) return;
      this.openInNewTabIfBrowser(`/history/${this.episodeId}`);
    },
    async validateListeningEpisode(episodeId) {
      try {
        if (!episodeId) return false;
        var patientId = await this.$dbGet(`episodes/${episodeId}/uid`);
        if (!patientId) return;
        var isSubscribed = await this.$dbGet(
          `subscribe/${patientId}/${this.$uid}/isSubscribed`
        );
        return isSubscribed || false;
      } catch (err) {
        console.log("validateListeningEpisode Error", err.message);
        return false;
      }
    },
    async registerPhoneWatcher() {
      if (!this.phone) return;
      this.$dbWatcher(
        `patients/${this.phone}/currentEpisodeId`,
        async (episodeId) => {
          this.episodeId = episodeId;
          if (!(await this.validateListeningEpisode(episodeId))) return;
          if (!episodeId || episodeId == "none") {
            this.showIcon = false;
            this.$emit("doneWatching", this.phone);
            return;
          }
          this.$emit("startWatching", this.phone);
          this.showIcon = true;
          this.playNof();
          this.$dbWatcher(
            `episodes/${episodeId}/datapoints`,
            async (datapoints) => {
              if (!datapoints || !datapoints.length) return;
              var result = await this.calibBleSeverity(
                datapoints[datapoints.length - 1]
              );
              if (result == BleSeverity.Severe) {
                this.badgeColorCode = "#F06292";
              } else if (result == BleSeverity.High) {
                this.badgeColorCode = "#FFA726";
              } else if (result == BleSeverity.Normal) {
                this.badgeColorCode = "#66BB6A";
              } else {
                this.badgeColorCode = "#00BCD4";
              }
            }
          );
        }
      );
    },
    async renderBleAnimation() {
      // sleep a little to wait for component to mount success
      await this.sleep(2000);
      // then lottie can render ble icon
      lottie.loadAnimation({
        container: this.$refs.lav2Container,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/lottie/ble3.json",
      });
    },
  },
};
</script>
